import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { MessageTypes } from 'hashconnect';
import { Subscription, timeout } from 'rxjs';
import { RegisterLinkComponent } from 'src/app/v2/shared/components/register/register-link.component';
import { FrontendWalletActionTypes } from 'src/app/v2/shared/models/security/provider-action-types';
import { NotificationService, NotificationType, SecurityService, ServiceResultStatus } from 'src/app/v2/shared/services';
import { AccountService } from 'src/app/v2/shared/services/account.service';
import { LocalWalletsService } from 'src/app/v2/shared/services/local-wallets.service';
import { environment } from 'src/environments/environment';
import QRCode from 'qrcode'
import { QrCodeWalletTypes } from 'src/app/v2/shared/enums/qr-code-wallet-types';
import { ISecurityIdentityDetails } from 'src/app/v2/shared/models/security/security-identity-details';
import { networkInterfaces } from 'os';
import { debug } from 'console';

@Component({
  selector: 'app-link-accounts',
  templateUrl: './link-accounts.component.html',
  styleUrls: ['./link-accounts.component.scss']
})
export class LinkAccountsComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('modal') modalRef?: ElementRef;
  @ViewChild('xummModal') xummModalRef?: ElementRef;
  @ViewChild('registerLink') registerLink?: RegisterLinkComponent;

  hasXrplWallet: boolean = false;
  hasHederaWallet: boolean = false;
  hasEthereumWallet: boolean = false;
  hasXdcWallet: boolean = false;
  hasFlareWallet: boolean = false;
  hasSongbirdWallet: boolean = false;
  hasBtcWallet: boolean = false;

  formError: boolean = false;
  hasXUser: boolean = false;
  modal: any;
  qrCodeModal: any;
  waitingMessage: string | undefined;
  notificationSubscription?: Subscription;

  walletType: QrCodeWalletTypes | undefined;
  qrCodeImgData: string | undefined;
  signInQrUrl: SafeResourceUrl | undefined;

  hederaPairingKey: string | undefined;
  hasHederaPairingKey: boolean = false;
  hasHederaPlugin: boolean = false;

  loggedInSecurityIdentityDetails: ISecurityIdentityDetails | undefined;
  loggedInProviderName: string = "";
  loggedInProviderReference: String = "";
  showLoggedInProviderReference: boolean = false;

  xpassIdentifier: ISecurityIdentityDetails | undefined;
  xrplIdentifiers: ISecurityIdentityDetails[] | undefined;
  xdcIdentifiers: ISecurityIdentityDetails[] | undefined;
  ethereumIdentifiers: ISecurityIdentityDetails[] | undefined;
  btcIdentifiers: ISecurityIdentityDetails[] | undefined;
  flareIdentifiers: ISecurityIdentityDetails[] | undefined;
  songbirdIdentifiers: ISecurityIdentityDetails[] | undefined;

  hederaIdentifiers: ISecurityIdentityDetails[]| undefined;
  hederaConnectedAccount: string | undefined;
  hederaConnectedAccountConnected: boolean = false;
  hashPackConnectionStatus: string = "Deactivated";
  ethereumConnectionStatus: string = "Deactivated";
  
  hashConnectWalletIsNew: boolean = false;

  qrLinkError = false;

  qrCodeModalHeaderMessage = "Scan this code to complete your transaction"
  qrCodeModalFooterMessage = "click/tap here to open with Xumm"
  showQrCodeModalHeaderMessage = false;
  showQrCodeModalFooterMessage = true;

  activeEthereumChain = 0;

  connectedGenericEthereumAccount = '';
  connectedEthereumAccount = '';
  connectedXdcAccount = '';
  connectedFlareAccount = '';
  connectedSongbirdAccount = '';

  ethereumWalletIsNew: boolean = false;
  xdcWalletIsNew: boolean = false;
  flareWalletIsNew: boolean = false;
  songbirdWalletIsNew: boolean = false;
  btcWalletIsNew: boolean = false;

  satsConnectDetected: boolean = false;
  uniSatsDetected: boolean = false;

  uniSatsConnectionStatus: string = "Deactivated";
  satsConnectConnectionStatus: string = "Deactivated";

  uniSatsWalletAddress: string = "";
  satsConnectWalletAddress: string = "";

  connectedBtcAccount = '';
  btcWalletManagerProviderGuid: string = '';

  

  constructor(
    private securityService: SecurityService,
    private accountService: AccountService,
    private window: Window,
    private notificationService: NotificationService,
    private sanitizer: DomSanitizer,
    private localWalletsService: LocalWalletsService
  ) {

  }

  async ngOnInit(): Promise<void> {
    this.getSecurityDetails(true);

    this.notificationSubscription = this.notificationService.listenFor(NotificationType.PaymentInstrumentAdded)
      .subscribe((response: any) => {
        this.getSecurityDetails(true);
        this.closeModal();
      });

    this.notificationSubscription = this.notificationService.listenFor(NotificationType.PaymentInstrumentFailed)
    .subscribe((response: any) => {
      this.qrLinkError = true;
      this.qrCodeImgData = undefined;
      this.signInQrUrl = undefined;
    });

    this.notificationSubscription = this.notificationService.listenFor(NotificationType.AddCryptoWalletComplete)
    .subscribe((response: any) => {
      this.getSecurityDetails(true);
      this.closeModal();
    });

    this.localWalletsService.btcWalletAdded.subscribe(wallet => {
      
      let walletAsObject = wallet as ISecurityIdentityDetails;
      if (walletAsObject && walletAsObject.securityIdentityGuid) {

        if (!this.btcIdentifiers) {
          this.btcIdentifiers = [];
        }

        this.btcIdentifiers?.push(walletAsObject);
        this.IsBtcWalletNew();
      }
      
    });

    this.localWalletsService.btcPluginsFound.subscribe(result => {

      if (result.satsConnect) {  
        this.satsConnectDetected = true;
        this.satsConnectConnectionStatus = "Active";
      }

      if (result.uniSats) {    
        this.uniSatsDetected = true;    
        this.uniSatsConnectionStatus = "Active";
      }

    })

    this.localWalletsService.xrplWalletAdded.subscribe(wallet => {

      let walletAsObject = wallet as ISecurityIdentityDetails;
      if (walletAsObject && walletAsObject.securityIdentityGuid) {

        if (!this.xrplIdentifiers) {
          this.xrplIdentifiers = [];
        }

        this.xrplIdentifiers?.push(walletAsObject);
        this.qrCodeModal?.hide();

      }
    }),

    await this.setupHederaEvents();
    await this.setupEVMWalletEvents();
    await this.setupUniSatEvents();

    await this.checkForBtcWallets();

    
  }

  ngOnDestroy(): void {
    this.notificationSubscription?.unsubscribe();
  }

  ngAfterViewInit() {
    this.modal = new this.window.bootstrap.Modal(this.modalRef?.nativeElement, {
      backdrop: 'static',
      keyboard: false
    });
    this.qrCodeModal = new this.window.bootstrap.Modal(this.xummModalRef?.nativeElement, {
      backdrop: 'static',
      keyboard: false
    });
  }

  getSecurityDetails(forceReload: boolean = false) {

    this.securityService.getAccountDetails(forceReload).subscribe(result => {

      if (result.status === ServiceResultStatus.Success && result.data) {

        this.loggedInSecurityIdentityDetails = result.data.loggedInWithSecurityIdentityDetails;

        this.loggedInProviderName = this.loggedInSecurityIdentityDetails!.providerName;
        this.showLoggedInProviderReference = this.loggedInSecurityIdentityDetails?.providerGuid != environment.xUserProviderGuid;
        this.loggedInProviderReference = this.loggedInSecurityIdentityDetails!.identityName;

        this.hasXrplWallet = this.securityService.hasSecurityIdentity(environment.xrplBlockchainProviderGuid, result.data);
        this.hasHederaWallet = this.securityService.hasSecurityIdentity(environment.hederaProviderGuid, result.data);
        this.hasXUser = this.securityService.hasXUser(result.data);

        this.hasEthereumWallet = this.securityService.hasSecurityIdentity(environment.ethereumProviderGuid,result.data);
        this.hasXdcWallet = this.securityService.hasSecurityIdentity(environment.xdcProviderGuid,result.data);
        this.hasBtcWallet = this.securityService.hasSecurityIdentity(environment.btcProviderGuid,result.data);
        this.hasFlareWallet = this.securityService.hasSecurityIdentity(environment.flareProviderGuid,result.data);
        this.hasSongbirdWallet = this.securityService.hasSecurityIdentity(environment.songbirdProviderGuid,result.data);
        
        if (this.hasXrplWallet) {
          this.xrplIdentifiers = this.securityService.getWalletsForProvider(result.data, environment.xrplBlockchainProviderGuid);
        } else {
          this.xrplIdentifiers = undefined;
        }

        if (this.hasXUser) {
          this.xpassIdentifier = this.securityService.getWalletsForProvider(result.data, environment.xUserProviderGuid)![0];
        } else {
          this.xpassIdentifier = undefined;
        }

        if (this.hasHederaWallet) {
          this.hederaIdentifiers = this.securityService.getWalletsForProvider(result.data, environment.hederaProviderGuid);
          this.IsHashConnectWalletNew();
        } else {
          this.hederaIdentifiers = undefined;
          this.hashConnectWalletIsNew = false;
        }

        if (this.hasEthereumWallet){
          this.ethereumIdentifiers = this.securityService.getWalletsForProvider(result.data, environment.ethereumProviderGuid)
          this.IsEthereumWalletNew();
        }else{
          this.ethereumIdentifiers = undefined
          this.ethereumWalletIsNew = false;
        }

        if (this.hasFlareWallet){
          this.flareIdentifiers = this.securityService.getWalletsForProvider(result.data, environment.flareProviderGuid)
          this.IsFlareWalletNew();
        }else{
          this.flareIdentifiers = undefined
          this.flareWalletIsNew = false;
        }

        if (this.hasSongbirdWallet){
          this.songbirdIdentifiers = this.securityService.getWalletsForProvider(result.data, environment.songbirdProviderGuid)
          this.IsSongbirdWalletNew();
        }else{
          this.songbirdIdentifiers = undefined
          this.songbirdWalletIsNew = false;
        }

        if (this.hasXdcWallet){
          this.xdcIdentifiers = this.securityService.getWalletsForProvider(result.data, environment.xdcProviderGuid)
          this.IsXdcWalletNew();
        }else{
          this.xdcIdentifiers = undefined
          this.xdcWalletIsNew = false;
        }

        if (this.hasBtcWallet){
          this.btcIdentifiers = this.securityService.getWalletsForProvider(result.data, environment.btcProviderGuid)
          this.IsBtcWalletNew();
        }else{
          this.btcIdentifiers = undefined
          this.btcWalletIsNew = false;
        }

      }
    });
  }

  closeModal() {
    this.modal.hide();
    this.qrCodeModal.hide();
    this.qrLinkError = false;
  }

  canRemoveSecurityIdentity(securityIdentityDetails: ISecurityIdentityDetails) : boolean {

    return securityIdentityDetails.securityIdentityGuid != this.loggedInSecurityIdentityDetails?.securityIdentityGuid

  }

  async removeSecurityIdentity(securityIdentityGuid: string) {

    this.accountService.removeCrytoWallet(securityIdentityGuid).subscribe(result => {

      setTimeout(() => {
        this.getSecurityDetails(true);
      }, 500);
      

    });

  }


  // XS user methods
  addXuserToAccount() {
    this.modal.show();
  }

  // Xumm methods
  linkXumm() {

      this.securityService.setupAddCryptoWalletSecurityMonitor(() => {
        this.accountService.InitializeAddCryptoWallet(this.securityService.getClientGuid(), environment.xummProviderGuid, environment.xrplBlockchainProviderGuid).subscribe((response: any) => {
        
          this.qrCodeModalFooterMessage = "click/tap here to open with Xumm"
          this.qrCodeImgData = response.data.data.qrCodeImage;
          this.signInQrUrl = this.sanitizer.bypassSecurityTrustResourceUrl(response.data.data.qrCodeUrl);
          this.showQrCodeModalHeaderMessage = false;
          this.showQrCodeModalFooterMessage = true;
          this.walletType = QrCodeWalletTypes.Xumm;
          this.qrCodeModal?.show();
          this.waitingMessage = undefined;
          
        });
      });
       
  }

  linkxdc(){
  }


  // HashConnect/HashPack/Hedera methods
  private async setupHederaEvents() {

    this.localWalletsService.hashConnectPluginFound.subscribe(found => this.hasHederaPlugin = found);
    this.localWalletsService.hashConnectWalletStatus.subscribe(status => this.hashPackConnectionStatus = status);

    this.localWalletsService.hashConnectPairingCode.subscribe(async hashpackConnectCode => {
      this.hederaPairingKey = hashpackConnectCode;
      this.hasHederaPairingKey = (hashpackConnectCode != undefined && hashpackConnectCode != "");
    })

    this.localWalletsService.hashConnectAccount.subscribe(account => {
      this.hederaConnectedAccount = account;
      this.hederaConnectedAccountConnected = account != '';
      this.IsHashConnectWalletNew();
      this.qrCodeModal?.hide();
    });

    this.localWalletsService.hederaWalletAdded.subscribe(wallet => {

      let walletAsObject = wallet as ISecurityIdentityDetails;
      if (walletAsObject && walletAsObject.securityIdentityGuid) {

        if (!this.hederaIdentifiers) {
          this.hederaIdentifiers = [];
        }

        this.hederaIdentifiers?.push(walletAsObject);
        this.IsHashConnectWalletNew();

      }
    });

    await this.localWalletsService.connectHashConnectWallet();
    
  }

  // EVM Generic wallets
  private async setupEVMWalletEvents() {

    this.localWalletsService.ethereumWalletAdded.subscribe(wallet => {
        
      let walletAsObject = wallet as ISecurityIdentityDetails;
      if (walletAsObject && walletAsObject.securityIdentityGuid) {

        if (!this.ethereumIdentifiers) {
          this.ethereumIdentifiers = [];
        }

        this.ethereumIdentifiers?.push(walletAsObject);
        this.IsEthereumWalletNew()
      }
      
    });

    this.localWalletsService.flareWalletAdded.subscribe(wallet => {
      
      let walletAsObject = wallet as ISecurityIdentityDetails;
      if (walletAsObject && walletAsObject.securityIdentityGuid) {

        if (!this.flareIdentifiers) {
          this.flareIdentifiers = [];
        }

        this.flareIdentifiers?.push(walletAsObject);
        this.IsXdcWalletNew()
      }
      
    });

    this.localWalletsService.songbirdWalletAdded.subscribe(wallet => {
      
      let walletAsObject = wallet as ISecurityIdentityDetails;
      if (walletAsObject && walletAsObject.securityIdentityGuid) {

        if (!this.songbirdIdentifiers) {
          this.songbirdIdentifiers = [];
        }

        this.songbirdIdentifiers?.push(walletAsObject);
        this.IsXdcWalletNew()
      }
      
    });

    this.localWalletsService.xdcWalletAdded.subscribe(wallet => {
      
      let walletAsObject = wallet as ISecurityIdentityDetails;
      if (walletAsObject && walletAsObject.securityIdentityGuid) {

        if (!this.xdcIdentifiers) {
          this.xdcIdentifiers = [];
        }

        this.xdcIdentifiers?.push(walletAsObject);
        this.IsXdcWalletNew()
      }
      
    });


    this.localWalletsService.activeEthereumChain.subscribe(chain => {
      this.activeEthereumChain = chain.chainId;
      this.setupEthereumWallet();
    });

    this.localWalletsService.activeEthereumAccount.subscribe(account => {

      this.connectedGenericEthereumAccount = account;
      this.setupEthereumWallet();
           
    });    

    this.localWalletsService.generalEvmWalletStatus.subscribe(status => {
      this.ethereumConnectionStatus = status.status;
    })

    await this.localWalletsService.connectEthereumWallet(false);

  }
  private setupEthereumWallet() {

    this.connectedEthereumAccount = '';
    this.connectedXdcAccount = '';
    this.connectedFlareAccount = '';
    this.connectedSongbirdAccount = '';

    if (this.activeEthereumChain == environment.ethereumChainId) {
      this.connectedEthereumAccount = this.connectedGenericEthereumAccount;
      this.IsEthereumWalletNew();
    } else if (this.activeEthereumChain == environment.xdcChainId) {
      this.connectedXdcAccount = this.connectedGenericEthereumAccount;
      this.IsXdcWalletNew();
    } else if (this.activeEthereumChain == environment.flareChainId) {
      this.connectedFlareAccount = this.connectedGenericEthereumAccount;
      this.IsFlareWalletNew();
    } else if (this.activeEthereumChain == environment.songbirdChainId) {
      this.connectedSongbirdAccount = this.connectedGenericEthereumAccount;
      this.IsSongbirdWalletNew();
    } 

  }


  private IsHashConnectWalletNew() {
    this.hashConnectWalletIsNew = !this.hederaIdentifiers || (this.hederaIdentifiers?.find(e => e.externalIdentifier == this.hederaConnectedAccount) == undefined)
    return this.hashConnectWalletIsNew;
  }
  private IsEthereumWalletNew(){
    this.ethereumWalletIsNew = !this.ethereumIdentifiers || (this.ethereumIdentifiers?.find(e => e.externalIdentifier.toUpperCase() == this.connectedEthereumAccount.toUpperCase()) == undefined)
    return this.ethereumWalletIsNew;
  }
  private IsXdcWalletNew(){
    this.xdcWalletIsNew = !this.xdcIdentifiers || (this.xdcIdentifiers?.find(e => e.externalIdentifier.toUpperCase() == this.connectedXdcAccount.toUpperCase()) == undefined)
    return this.xdcWalletIsNew;
  }
  private IsBtcWalletNew(){
    this.btcWalletIsNew = !this.btcIdentifiers || (this.btcIdentifiers?.find(e => e.externalIdentifier.toUpperCase() == this.connectedBtcAccount.toUpperCase()) == undefined)
    return this.btcWalletIsNew;
  }
  private IsFlareWalletNew(){
    this.flareWalletIsNew = !this.flareIdentifiers || (this.flareIdentifiers?.find(e => e.externalIdentifier.toUpperCase() == this.connectedFlareAccount.toUpperCase()) == undefined)
    return this.btcWalletIsNew;
  }
  private IsSongbirdWalletNew(){
    this.songbirdWalletIsNew = !this.songbirdIdentifiers || (this.songbirdIdentifiers?.find(e => e.externalIdentifier.toUpperCase() == this.connectedSongbirdAccount.toUpperCase()) == undefined)
    return this.btcWalletIsNew;
  }



  async connectEthereumWallet() {
    await this.localWalletsService.connectEthereumWallet()
  }

  async openHashpackModal() {

      this.walletType = QrCodeWalletTypes.HashConnect;
      this.qrCodeModalHeaderMessage = "Scan this code to link Hash Connect"
      this.qrCodeModalFooterMessage = ""
      this.qrCodeImgData = await QRCode.toDataURL(this.hederaPairingKey!);
      this.qrCodeModal?.show();
  }
  async disconnectHashConnect() {

    await this.localWalletsService.disconnectHashConnectPairing();
  }
  async reconnectHashConnect() {

    await this.localWalletsService.disconnectHashConnectPairing();
    await this.localWalletsService.connectHashConnectWallet();

  }
  async addHederaWalletWithHashConnect() {

    await this.localWalletsService.addHederaWalletWithHashPack();

  }
  openHederaPlugin() {
    this.localWalletsService.connectHashConnectWalletLocal();
  }

  async addEthereumWallet(cryptoName: string) {
    this.localWalletsService.addEtheriumWallet(cryptoName);
  }

  // Btc wallets
  async checkForBtcWallets() {

    this.localWalletsService.checkForBtcWallets();

  }
  async connectUniSatsWallet() {
    await this.localWalletsService.connectUniSatsWallet()
  }
  async connectSatsConnectWallet() {
    await this.localWalletsService.connectEthereumWallet()
  }
  async addBtcWallet() {
    this.localWalletsService.addBtcWallet(this.btcWalletManagerProviderGuid);
  }

  setupUniSatEvents() {

    this.localWalletsService.activeUniSatsAccount.subscribe(account => {
      this.uniSatsWalletAddress = account;
      this.connectedBtcAccount = account;
      this.btcWalletManagerProviderGuid = environment.uniSatProviderGuid;
      this.IsBtcWalletNew();
      console.log(`UniSats connected to ${account}`);
    });
    this.localWalletsService.activeUniSatsNetwork.subscribe(network => {
      console.log(`UniSats connected to ${network}`);
    });

  }


  submit(registerGroup: FormGroup) {
    this.accountService.addXuserToAccount({
      email: registerGroup.get('email')?.value,
      password: registerGroup.get('password')?.value,
      confirmPassword: registerGroup.get('password')?.value
    }).subscribe(response => {
      if (response.status !== ServiceResultStatus.Success) {
        this.registerLink?.hasError();
      } else {
        this.getSecurityDetails(true);
        this.closeModal();
      }
    });
  }
  cancelSignIn() {
    this.closeModal();
  }

}


import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { UiService } from '../../services/ui.service';
import { AccountService } from '../../services/account.service';
import { NotificationService, NotificationType, SecurityService, ServiceResultStatus } from '../../services';
import { QrCodeWalletTypes } from '../../enums/qr-code-wallet-types';
import { LocalWalletsService } from '../../services/local-wallets.service';
import QRCode from 'qrcode'
import { ISecurityIdentityDetails } from '../../models/security/security-identity-details';
import { EntityIdHelper } from '@hashgraph/sdk';

@Component({
  selector: 'app-sign-in-v2',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.scss']
})
export class SignInComponentV2 implements OnInit, AfterViewInit {
  @ViewChild('modal') modalRef?: ElementRef;

  qrCodeImgData: string | undefined;
  signInQrUrl: SafeResourceUrl | undefined;
  showDetails: boolean = false;
  waitingMessage: string | undefined;
  signInModal: any;
  loginGroup: FormGroup;
  registerGroup: FormGroup;
  forgotPasswordGroup: FormGroup;

  formError: boolean = false;
  formSubmitted: boolean = false;
  formErrorMessage: string | undefined;
  forgotPasswordSuccess: boolean = false;

  slideIndex = 2;
  redirect: boolean = true;

  walletType: QrCodeWalletTypes | undefined;
  loginModalHeaderMessage = "Scan this code to complete your transaction";
  loginModalFooterMessage = "click/tap here to open with Xumm";
  showHeaderMessage: boolean = false;
  showFooterMessage: boolean = false;

  hederaIdentifiers: ISecurityIdentityDetails[]| undefined;
  hederaConnectedAccount: string | undefined;
  hederaConnectedAccountConnected: boolean = false;
  hashPackConnectionStatus: string = "Deactivated";
  hederaPairingKey: string | undefined;
  hasHederaPairingKey: boolean = false;
  hasHederaPlugin: boolean = false;
  showHashPackPairingQrCodeData: boolean = false;
  showHashPackLogin: boolean = false;


  genericEvmConnectionStatus: string = "Disconnected";

  ethereumIdentifiers: ISecurityIdentityDetails[] | undefined;
  connectedEthereumAccount = '';  
  
  xdcIdentifiers: ISecurityIdentityDetails[]| undefined;
  connectedXdcAccount = '';

  flareIdentifiers: ISecurityIdentityDetails[]| undefined;
  connectedFlareAccount = '';

  songbirdIdentifiers: ISecurityIdentityDetails[]| undefined;
  connectedSongbirdAccount = '';

  activeEthereumChain = 0;
  connectedGenericEthereumAccount = '';
  
  
  uniSatsConnectionStatus: string = "Deactivated";
  satsConnectConnectionStatus: string = "Deactivated";

  uniSatsWalletAddress: string = "";
  satsConnectWalletAddress: string = "";

  satsConnectDetected: boolean = false;
  uniSatsDetected: boolean = false;

  connectedBtcAccount = '';
  btcWalletManagerProviderGuid = '';

  xdcChainId = environment.xdcChainId;
  ethereumChainId = environment.ethereumChainId;
  flareChainId = environment.flareChainId;
  songbirdChainId = environment.songbirdChainId;

  constructor(
    private notificationService: NotificationService,
    private sanitizer: DomSanitizer,
    private fb: FormBuilder,
    private window: Window,
    private accountService: AccountService,
    private router: Router,
    private securityService: SecurityService,
    private uiService: UiService,
    private localWalletsService: LocalWalletsService
  ) {
      this.loginGroup = this.fb.group({
        email: ['', [Validators.required, Validators.email]],
        password: ['', Validators.required]
      });

      this.registerGroup = this.fb.group({
        email: ['', [Validators.required, Validators.email]],
        username: ['', Validators.required],
        password: ['', Validators.required],
        terms: [false, Validators.requiredTrue]
      });

      this.forgotPasswordGroup = this.fb.group({
        email: ['', [Validators.required, Validators.email]]
      });

    }

  async ngOnInit(): Promise<void>  {
    this.uiService.signInModal.subscribe(({ open, shouldRedirect }) => {
      this.redirect = shouldRedirect;

      if (open) {
        this.showLogin();
      } else {
        this.clearSignIn(false);
      }
    })

    let subscribe = <NotificationMessage>(notificationType: NotificationType, handler: (message: any | undefined) => void) => {
      this.notificationService.listenFor<NotificationMessage>(notificationType).subscribe(handler.bind(this));
    };

    subscribe(NotificationType.SignInInitialized, signInInstruction => {

      if (signInInstruction.data.walletProviderGuid == environment.xummProviderGuid.toLocaleLowerCase()){
        this.qrCodeImgData = signInInstruction.data.qrCodeImage;
        this.signInQrUrl = this.sanitizer.bypassSecurityTrustResourceUrl(signInInstruction.data.qrCodeUrl);
        this.waitingMessage = undefined;
        this.walletType = QrCodeWalletTypes.Xumm;
        this.showHeaderMessage = false;
        this.showFooterMessage = true;
      }
    });

    subscribe(NotificationType.SignInRejected, () => {
      this.qrCodeImgData = undefined;
      this.signInQrUrl = undefined;
      this.waitingMessage = 'Sign-in failed: Please try again';
    });

    subscribe(NotificationType.SignInRejected, () => {
      this.qrCodeImgData = undefined;
      this.signInQrUrl = undefined;
      this.waitingMessage = 'Sign-in rejected';
      window.setTimeout(() => { this.clearSignIn(false); }, 2000);
    });

    subscribe(NotificationType.SignInApproved, signInResponse => {
      if (signInResponse.isFirstSignIn) {
        this.showDetails = true;
        this.waitingMessage = undefined;
        this.slideIndex = 1;
      }
      else {
        this.successfulLogin();
      }
    });

    subscribe(NotificationType.SignInRejected, this.clearSignIn);

    this.localWalletsService.hederaWalletAdded.subscribe(wallet => {

      let walletAsObject = wallet as ISecurityIdentityDetails;
      if (walletAsObject && walletAsObject.securityIdentityGuid) {

        if (!this.hederaIdentifiers) {
          this.hederaIdentifiers = [];
        }

        this.hederaIdentifiers?.push(walletAsObject);

      }

    })

    this.localWalletsService.btcPluginsFound.subscribe(result => {

      if (result.satsConnect) {  
        this.satsConnectDetected = true;
        this.satsConnectConnectionStatus = "Active";
      }

      if (result.uniSats) {    
        this.uniSatsDetected = true;    
        this.uniSatsConnectionStatus = "Active";
      }

    })

    await this.setupHederaEvents();
    await this.setupEthereumEvents();

    await this.setupUniSatEvents();
    await this.checkForBtcWallets();

  }

  ngAfterViewInit() {
    this.signInModal = new this.window.bootstrap.Modal(this.modalRef?.nativeElement, {
      backdrop: 'static',
      keyboard: false
    });
  }

  switchSlide(index: number) {
    this.loginGroup.reset();
    this.registerGroup.reset();
    this.forgotPasswordGroup.reset();
    this.formError = false;
    this.formSubmitted = false;
    this.formErrorMessage = undefined;
    this.forgotPasswordSuccess = false;

    if (index === 0) {
      this.securityService.signIn(environment.xummProviderGuid, environment.xrplBlockchainProviderGuid);
    } else if (index === 0) {
      this.securityService.signIn(environment.xummProviderGuid, environment.xrplBlockchainProviderGuid);
    }

    this.slideIndex = index;
  }

  cancelSignIn() {
    this.notificationService.send(NotificationType.SignInRejected, undefined);
  }

  clearSignIn(gotoAccount: boolean = false) {

    this.switchSlide(2);
    this.signInModal?.hide();
    this.showDetails = false;
    this.qrCodeImgData = undefined;
    this.signInQrUrl = undefined;
    this.waitingMessage = undefined;

    if (gotoAccount){
      this.router.navigate(['/account/details']);
    } 
    

  }

  login() {
    this.formSubmitted = true;
    this.formError = false;

    if (!this.loginGroup.valid) {
      return;
    }

    this.waitingMessage = 'Logging in';
    this.accountService.login({
      clientGuid: this.notificationService.getClientGuid(),
      providerGuid: environment.xUserProviderGuid,
      signInInfo: {
        emailAddress: this.loginGroup.get('email')?.value,
        password: this.loginGroup.get('password')?.value
      }
    }).subscribe(response => {
      if (response.status !== ServiceResultStatus.Success) {
        this.formError = true;
        this.waitingMessage = undefined;
      } else {
        this.notificationService.send(NotificationType.SignInApproved, { token: response.data });
      }
    });
  }

  registerSuccess(token: string) {
    this.notificationService.send(NotificationType.SignInApproved, { token });
  }

  forgotPassword() {
    this.formSubmitted = true;
    this.formErrorMessage = '';

    if (!this.forgotPasswordGroup.valid) {
      return;
    }

    this.waitingMessage = 'Sending email';
    this.accountService.forgotPassword({
      emailAddress: this.forgotPasswordGroup.get('email')?.value,
    }).subscribe(response => {
      if (response.status !== ServiceResultStatus.Success) {
        this.formError = true;
        this.formErrorMessage = response.message;
      } else {
        this.forgotPasswordSuccess = true;
      }

      this.waitingMessage = undefined;
    });
  }

  showLogin() {
    this.switchSlide(2);
    this.signInModal.show();
  }

  successfulLogin() {
    this.clearSignIn(true);

  }

  openHederaPlugin() {
    this.localWalletsService.connectHashConnectWalletLocal();
  }

  async connectUniSatsWallet() {
    await this.localWalletsService.connectUniSatsWallet()
  }
  setupUniSatEvents() {

    this.localWalletsService.activeUniSatsAccount.subscribe(account => {
      this.uniSatsWalletAddress = account;
      this.connectedBtcAccount = account;
      this.btcWalletManagerProviderGuid = environment.uniSatProviderGuid;
      console.log(`UniSats connected to ${account}`);
    });
    this.localWalletsService.activeUniSatsNetwork.subscribe(network => {
      console.log(`UniSats connected to ${network}`);
    });

  }
  async checkForBtcWallets() {

    this.localWalletsService.checkForBtcWallets();

  }
  async loginWithUniSat() {
    this.localWalletsService.signInWithBitCoin(environment.uniSatProviderGuid);
  }


  async showHashPackPairingQrCode() {

      this.walletType = QrCodeWalletTypes.HashConnect;
      this.loginModalHeaderMessage = "Scan this code to link Hash Connect"
      this.loginModalFooterMessage = ""
      this.qrCodeImgData = await QRCode.toDataURL(this.hederaPairingKey!);
      this.showHashPackPairingQrCodeData = true;
  }
  async disconnectHashConnect() {

    await this.localWalletsService.disconnectHashConnectPairing();
  }
  async loginWithHashPack() {
    this.localWalletsService.signInWithHedera();
  }

  private async setupHederaEvents() {

    this.localWalletsService.hashConnectPluginFound.subscribe(found => this.hasHederaPlugin = found);
    this.localWalletsService.hashConnectWalletStatus.subscribe(status => this.hashPackConnectionStatus = status);

    this.localWalletsService.hashConnectPairingCode.subscribe(async hashpackConnectCode => {
      this.hederaPairingKey = hashpackConnectCode;
      this.hasHederaPairingKey = (hashpackConnectCode != undefined && hashpackConnectCode != "");
    })

    this.localWalletsService.hashConnectAccount.subscribe(account => {
      this.hederaConnectedAccount = account;
      this.hederaConnectedAccountConnected = account != '';
      this.showHashPackPairingQrCodeData =false;
      this.showHashPackLogin = this.hederaConnectedAccountConnected;
    });

    await this.localWalletsService.connectHashConnectWallet();

  }

  private async setupEthereumEvents() {
    
    this.localWalletsService.ethereumWalletAdded.subscribe(wallet => {
        
      let walletAsObject = wallet as ISecurityIdentityDetails;
      if (walletAsObject && walletAsObject.securityIdentityGuid) {

        if (!this.ethereumIdentifiers) {
          this.ethereumIdentifiers = [];
        }

        this.ethereumIdentifiers?.push(walletAsObject);
      }
      
    });

    this.localWalletsService.xdcWalletAdded.subscribe(wallet => {
        
      let walletAsObject = wallet as ISecurityIdentityDetails;
      if (walletAsObject && walletAsObject.securityIdentityGuid) {

        if (!this.xdcIdentifiers) {
          this.xdcIdentifiers = [];
        }

        this.xdcIdentifiers?.push(walletAsObject);
      }
      
    });

    this.localWalletsService.flareWalletAdded.subscribe(wallet => {
        
      let walletAsObject = wallet as ISecurityIdentityDetails;
      if (walletAsObject && walletAsObject.securityIdentityGuid) {

        if (!this.flareIdentifiers) {
          this.flareIdentifiers = [];
        }

        this.flareIdentifiers?.push(walletAsObject);
      }
      
    });

    this.localWalletsService.songbirdWalletAdded.subscribe(wallet => {
        
      let walletAsObject = wallet as ISecurityIdentityDetails;
      if (walletAsObject && walletAsObject.securityIdentityGuid) {

        if (!this.songbirdIdentifiers) {
          this.songbirdIdentifiers = [];
        }

        this.songbirdIdentifiers?.push(walletAsObject);
      }
      
    });


    this.localWalletsService.activeEthereumChain.subscribe(chain => {
      this.activeEthereumChain = chain.chainId;
      this.setupEthereumWallet();
    });

    this.localWalletsService.activeEthereumAccount.subscribe(account => {

      this.connectedGenericEthereumAccount = account;
      this.setupEthereumWallet();
           
    });    

    this.localWalletsService.generalEvmWalletStatus.subscribe(status => this.genericEvmConnectionStatus = status.status);

    await this.localWalletsService.connectEthereumWallet(false);

  }

  async connectEthereumWallet(): Promise<void> {
    await this.localWalletsService.connectEthereumWallet();
  }

  async SignInWithEthereum(cryptoPrividerName: string) : Promise<void> {
    this.localWalletsService.signInWithEtherium(cryptoPrividerName);
  }

  isIncorrectChain(chain: number): boolean{

    return this.activeEthereumChain > 0 && this.activeEthereumChain != chain
  }
  
  private setupEthereumWallet() {

    this.connectedEthereumAccount = '';
    this.connectedXdcAccount = '';
    this.connectedFlareAccount = '';
    this.connectedSongbirdAccount = '';

    if (this.activeEthereumChain == environment.ethereumChainId){
        
      this.connectedEthereumAccount = this.connectedGenericEthereumAccount;

    } else if (this.activeEthereumChain == environment.xdcChainId) {
      this.connectedXdcAccount = this.connectedGenericEthereumAccount;
    } else if (this.activeEthereumChain == environment.flareChainId) {
      this.connectedFlareAccount = this.connectedGenericEthereumAccount;
    } else if (this.activeEthereumChain == environment.songbirdChainId) {
      this.connectedSongbirdAccount = this.connectedGenericEthereumAccount;
    }

  }

}

import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { AccountDetailsPromoptComponent } from './components/account-details-prompt/account-details-prompt.component';
import { CheckboxComponent } from './components/checkbox/checkbox.component';
import { IconComponent } from './components/icon/icon.component';
import { RegisterLinkComponent } from './components/register/register-link.component';
import { RegisterComponent } from './components/register/register.component';
import { ScanQrCodeComponentV2 } from './components/scan-qr-code/scan-qr-code.component';
import { SignInComponentV2 } from './components/sign-in/sign-in.component';
import { SlideComponentV2 } from './components/slide/slide.component';
import { WaitingComponent } from './components/waiting/waiting.component';
import { SecurityInterceptor } from './interceptors/security-interceptor';
import { BlockchainWalletNamePipe, CurrencyAmountPipe, MiniEvmWalletAddressPipe, NftSearchPipe } from './pipes';
import { SecurityService } from './services';

export function initializeSecurityService(securityService: SecurityService) {
  return () => {
    return securityService.initialize();
  }
}

@NgModule({
  declarations: [
    SlideComponentV2,
    ScanQrCodeComponentV2,
    SignInComponentV2,
    RegisterComponent,
    RegisterLinkComponent,
    CurrencyAmountPipe,
    BlockchainWalletNamePipe,
    NftSearchPipe,
    IconComponent,
    WaitingComponent,
    AccountDetailsPromoptComponent,
    CheckboxComponent,
    MiniEvmWalletAddressPipe
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    RouterModule,
    CommonModule,
    RouterModule,
    HttpClientModule,
    ReactiveFormsModule,
    CarouselModule
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: initializeSecurityService,
      deps: [SecurityService],
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: SecurityInterceptor,
      deps: [SecurityService],
      multi: true
    }
  ],
  exports: [
    SlideComponentV2,
    ScanQrCodeComponentV2,
    SignInComponentV2,
    RegisterComponent,
    RegisterLinkComponent,
    CurrencyAmountPipe,
    BlockchainWalletNamePipe,
    NftSearchPipe,
    IconComponent,
    WaitingComponent,
    AccountDetailsPromoptComponent,
    CheckboxComponent,
    MiniEvmWalletAddressPipe
  ]
})
export class SharedModuleV2 { }

import { Location } from '@angular/common';
import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { IAccountDetails } from 'src/app/v2/shared/models';
import { NotificationService, NotificationType, SecurityService, ServiceResultStatus } from 'src/app/v2/shared/services';
import { AccountSectionService } from 'src/app/v2/shared/services/account-section.service';


@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss']
})
export class AccountLayoutComponent implements OnInit, AfterViewInit {
  @ViewChild('modal') modalRef?: ElementRef;

  menuItems = [
    { name: 'Account details', link: '/account/details' },
    { name: 'Link accounts', link: '/account/link-accounts' },
    { name: 'Payment', link: '/account/payments' },
    { name: 'Order history', link: '/account/history' },
    { name: 'XS Lock Vault', link: '/account/xs-lock-vault' },
    { name: 'Land', link: '/account/land' },
    { name: 'NFTs', link: '/account/nfts' },
  ]

  detailModal: any;
  subscriptions: Subscription[] = [];
  accountDetails:IAccountDetails | undefined;

  constructor(private router: Router,
    private location: Location,
    private accountSectionService: AccountSectionService,
    private securityService: SecurityService,
    private notificationService: NotificationService,
    private window: Window) { }

  ngOnInit(): void {

    if (this.router.url == '/account') {
      this.router.navigate(['/account/details'])
    }

    this.subscriptions.push(this.notificationService.listenFor(NotificationType.SignInApproved).subscribe(() => {
      this.loadDetails();
    }));

    this.subscriptions.push(this.notificationService.listenFor(NotificationType.SignOut).subscribe(() => {
      this.accountDetails = undefined;
    }));

    this.subscriptions.push(this.accountSectionService.modalSource
    .subscribe(value => {
      if (this.detailModal && value) {
        this.detailModal.show();
      }
    }));

    this.loadDetails();
    
  }

  ngAfterViewInit() {
    this.detailModal = new this.window.bootstrap.Modal(this.modalRef?.nativeElement, {
      backdrop: 'static',
      keyboard: false
    });
  }

  isActiveRoute(route: string): boolean {
    return this.router.isActive(route, false);
  }

  showAccountUserId() : boolean {

    if (this.accountDetails && this.accountDetails!.accountGuid) {
      return true;
    }

    return false;

  }
  loadDetails() {
    this.securityService.getAccountDetails(true).subscribe(result => {
      if (result.status === ServiceResultStatus.Success) {
        this.accountDetails = result.data;
      }
    });
  }

}

<div class="overlay" [ngClass]="{ 'active': navigationOpen }"></div>

<section class="side-menu" [ngClass]="{ 'active': navigationOpen }">
  <div class="side-menu__top">
    <img src="../../../assets/v2/images/logo.svg" alt="xSpectar" />
    <app-icon [icon]="'fa-times'" (click)="closeNavbar()"></app-icon>
  </div>

  <div class="side-menu__main">
    <nav class="side-menu__links">
      <div>
        <ul>
          <li>
            <a class="navigation-menu-link" href="{{environment.marketingSite}}metaverse.html"
              target="_blank">xSPECTARverse</a>
          </li>
          <!--<li> <a class="navigation-menu-link" href="#">Map</a></li>-->
          
          <li>
            <a class="navigation-menu-link" routerLink="buy-land" (click)="closeNavbar()">Buy Land</a>
          </li>

          <li>
            <a class="navigation-menu-link" routerLink="land-picking" (click)="closeNavbar()">Land Picking</a>
          </li>
          
          <li>
            <a class="navigation-menu-link" routerLink="bitmap-island-land-sale" (click)="closeNavbar()">Bitmap Island</a>
          </li>
          <!--<li> <a class="navigation-menu-link" href="#">Virtual art gallery</a></li>-->
        </ul>
        <ul>
          <!-- <li>
            <a class="navigation-menu-link" href="{{environment.marketingSite}}xspectar-tokens.html"
              target="_blank">Tokens</a>
          </li> -->
          <li>
            <a class="navigation-menu-link" href="{{environment.marketingSite}}xspectar-tokens.html"
              target="_blank">Buy xSPECTAR Tokens</a>
          </li>
          <li>
            <a class="navigation-menu-link" href="{{environment.marketingSite}}how-to-buy-tokens.html"
              target="_blank">How To Buy Tokens</a>
          </li>
          <li>
            <a class="navigation-menu-link" href="{{environment.marketingSite}}xs-lock.html" target="_blank">XS Lock Rewards</a>
          </li>
          <li>
            <a class="navigation-menu-link"
              href="https://xrpl.services/?issuer=rh5jzTCdMRCVjQ7LT6zucjezC47KATkuvv&amp;currency=xSPECTAR&amp;limit=88888888"
              target="_blank">Trustline</a>
          </li>
        </ul>
        <ul>
          <li>
            <a routerLink="/marketplace" class="navigation-menu-link" (click)="closeNavbar()">Marketplace</a>
          </li>
          <li>
            <a class="navigation-menu-link" href="{{environment.marketingSite}}xspectar-marketplace.html"
              target="_blank">About Marketplace</a>
          </li>
        </ul>
        <ul>
          <li>
            <a class="navigation-menu-link" href="{{environment.marketingSite}}xspectar-nft.html"
              target="_blank">NFTs </a>
          </li>
          <li>
            <a class="navigation-menu-link" href="https://xspectar.gitbook.io/whitepaper/" target="_blank">Whitepaper
            </a>
          </li>
          <!--<li> <a class="navigation-menu-link" href="#">About us </a></li>-->
        </ul>
        <ul>
          <li>
            <a class="navigation-menu-link" href="{{environment.marketingSite}}xspectar-tech.html"
              target="_blank">xSPECTAR TECH</a>
          </li>
        </ul>
      </div>
      <div>
        <ul>
          <!-- <li>
            <a class="navigation-menu-link" href="{{environment.marketingSite}}rich-list.html"
              target="_blank">Richlist</a>
          </li> -->
          <!-- <li>
            <a class="navigation-menu-link" href="{{environment.marketingSite}}roadmap.html"
              target="_blank">Roadmap</a>
          </li>
          <li>
            <a class="navigation-menu-link" href="{{environment.marketingSite}}news-and-press.html"
              target="_blank">News and press</a>
          </li>
          <li>
            <a class="navigation-menu-link" href="{{environment.marketingSite}}events.html"
              target="_blank">Events</a>
            </li> -->
        </ul>
        <ul>
          <li>
            <h6 class="fw-bold">Need support?</h6>
          </li>
          <li>
            <a class="navigation-menu-link" href="{{environment.marketingSite}}faqs.html" target="_blank">FAQ</a>
          </li>
          <!--<li> <a class="navigation-menu-link" href="#">Contact</a></li>-->
        </ul>
      </div>
    </nav>

    <app-footer-v2 [isMenuFooter]="true"></app-footer-v2>
  </div>
</section>

<div class="qrCode" *ngIf="qrCodeImgData">
  <p *ngIf="showHeaderMessage">{{headerMessage}}</p>


  <a *ngIf="walletType == QrCodeWalletTypes.Xumm" class="qrCode__link" [href]="signInQrUrl">
    <div class="qrCode__code">
      <img class="qrCode__img" [src]="qrCodeImgData" />
    </div>
    <p  *ngIf="showFooterMessage">
      <span>{{footerMessage}}</span>
    </p>
  </a>

  <div *ngIf="walletType == QrCodeWalletTypes.HashConnect" class="qrCode">
    
    <div class="qrCode__code">
      <img class="qrCode__img" [src]="qrCodeImgData" />
    </div>

    <button class="button button--secondary hedera-plugin" *ngIf="hasHederaPlugin" (click)="emitOpenHederaPlugin()">
      Open HashConnect plugin
    </button>

    <div class="hedera-pairing">
      <span>Pairing key</span>
      <input type="text" [value]="hederaPairingKey" />
      <img src="../../../../../assets/images/copy.png" (click)="copyHederPairingKey()" />
    </div>

    <p *ngIf="showFooterMessage">
      <span>{{footerMessage}}</span>
    </p>
  </div>

  <button class="button button--tertairy-alt" type="button" (click)="emitCancel()">
    Cancel
  </button>
</div>

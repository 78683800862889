<app-waiting
  [message]="'Loading land'"
  [isWaiting]="isLoading"
  [fullScreen]="false"
  *ngIf="isLoading">
</app-waiting>

<ng-container *ngIf="!isLoading">
  <div *ngIf="(sortedCollectionLandSaleAssets | keyvalue)?.length || freePreMintLandSaleAssets.length > 0 || finalLandPlotAssets.length > 0; else noLand">

    <div>
      <span>Total plots: </span>
      <span>{{totalPlots}}</span>
      <hr>
    </div>

    <div class="land">

      <div *ngIf="finalLandPlotAssets.length > 0" class="land__voucher land__voucher--fl-mint">
        <div>
          <h4>Land Plots</h4>

        </div>
        <div>
          <div>
            {{ finalLandPlotAssets.length }}
            <br/>
            Land Plots
          </div>
        </div>
      </div>

      <div *ngFor="let key of Object.keys(sortedCollectionLandSaleAssets)" class="land__voucher land__voucher--{{ replaceLandSalePackageNameForCSS(key) }}">
        <div>
          <img class="land__image" src="../../../../../assets/v2/images/land/{{replaceLandSalePackageNameForCSS(key)}}.png" alt="" />
          {{ key }}
        </div>
        <h4>Land Sale Parcel</h4>
        <div>
          Total parcels
          <span>{{ sortedCollectionLandSaleAssets[key].length }}</span>
        </div>
      </div>

      <div *ngIf="freePreMintLandSaleAssets.length > 0" class="land__voucher land__voucher--fl-mint">
        <div>
          <h4>Land Sale Voucher</h4>
          Free Pre Mint Land Plots
        </div>
        <div>
          <div>
            {{ freePreMintLandSalesPlots }}
            <br/>
            Land Plots
          </div>
        </div>
      </div>
    </div>
  </div>

  <ng-template #noLand>
    <p class="empty-state-para">We're sorry! You dont have any land yet in the xSPECTARVERSE. Click the link below to get your land now!</p>

    <a class="button button--secondary" routerLink="/buy-land">Buy land</a>
  </ng-template>
</ng-container>

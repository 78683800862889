import { DOCUMENT } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  hideFooter = true;
  isV2Route = true;
  isStyleSheetAdded = false;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private router: Router,
  ) {}

  ngOnInit(): void {
    this.router.events.subscribe(routeEvent => {
      if (routeEvent instanceof NavigationEnd) {
        this.hideFooter = routeEvent.url.toLowerCase().includes('welcome');

        document.querySelector('.allow-scroll')?.scroll(0,0);

        if (!this.isStyleSheetAdded) {

            this.document.querySelector('link[href="styles.css"]')?.remove();
            this.addStyleSheets('/stylesV2.css');

        }
      }
    });
  }

  addStyleSheets(src: string) {
    const link = this.document.createElement('link');
    link.setAttribute('rel', 'stylesheet');
    link.setAttribute('type', 'text/css');
    link.setAttribute('href', src);

    this.document.head.appendChild(link);
  }
}

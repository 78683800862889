import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PageLayoutComponent } from './v2/page-layout/page-layout.component';

const routes: Routes = [

  {
    path: '',
    component: PageLayoutComponent,
    children: [
      {
        path: '',
        loadChildren: () => import('./v2/default/default.module').then((m) => m.DefaultModule),
      },
      {
        path: 'wallet-test-omnia',
        loadChildren: () => import('./v2/wallet-tests-omnia/wallet-tests-omnia.module').then((m) => m.WalletTestsOmniaModule),
      },
      {
        path: 'welcome',
        loadChildren: () => import('./v2/default/default.module').then((m) => m.DefaultModule),
      },
      {
        path: 'account',
        loadChildren: () => import('./v2/account/account.module').then((m) => m.AccountNewModule),
      },
      {
        path: 'marketplace',
        loadChildren: () => import('./v2/marketplace/marketplace.module').then((m) => m.MarketplaceModule),
      },
      {
        path: 'land-sales',
        loadChildren: () => import('./v2/land-sale-purchase/land-sale-purchase.module').then((m) => m.LandSalePurchaseModule),
      },
      {
        path: 'bitmap-island-land-sale',
        loadChildren: () => import('./v2/btc-bitmaps/btc-bitmaps.module').then((m) => m.BtcBitmapsModule),
      },
      {
        path: 'land-sale-purchase',
        loadChildren: () => import('./v2/land-sale-purchase/land-sale-purchase.module').then((m) => m.LandSalePurchaseModule),
      },
      {
        path: 'buy-land',
        loadChildren: () => import('./v2/buy-land/buy-land.module').then((m) => m.BuyLandModule),
      },
      {
        path: 'land-picking',
        loadChildren: () => import('./v2/land-picking/land-picking.module').then((m) => m.LandPickingModule),
      },
      {
        path: 'xs-lock',
        loadChildren: () => import('./v2/xs-lock/xs-lock.module').then((m) => m.XsLockModule),
      },
      {
        path: 'reset',
        loadChildren: () => import('./v2/reset-password/reset-password.module').then((m) => m.ResetPasswordModule),
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }

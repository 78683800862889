import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { SecurityService } from '../services';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class SecurityInterceptor implements HttpInterceptor {

    constructor(private securityService: SecurityService) { }

    intercept(httpRequest: HttpRequest<any>, next: HttpHandler) : Observable<HttpEvent<any>> {
      if (this.securityService.isSignedIn && this.securityService.securityToken) {
        return next.handle(httpRequest.clone({
          headers: httpRequest.headers.set('Authorization', this.securityService.securityToken)
        }));
      }

      return next.handle(httpRequest);
    }
}

<div>

  <h3>Please note, you cannot remove the method you are currently logged in with.</h3>
  
  <div class="provider currentLogin">
    <span>Current method : {{loggedInProviderName}}</span>
    <span *ngIf="showLoggedInProviderReference">Reference : {{loggedInProviderReference}}</span>
  </div>
  
  <div class="provider">
    <h2>Xs-Pass</h2>
    <hr>

    <div>
      <button class="button button--primary" [disabled]="hasXUser" (click)="addXuserToAccount()">Add Xs-Pass</button>
      <p *ngIf="hasXUser && xpassIdentifier">
        xPass user already linked: {{ xpassIdentifier!.identityName }}
      </p>
    </div>

  </div>

  <div class="provider">
    <h2>Xrpl</h2>
    <hr>

    <div>
      <button class="button button--primary" (click)="linkXumm()">Link Xrpl account with Xumm</button>
    </div>

    <div  *ngIf="xrplIdentifiers && xrplIdentifiers.length > 0">

      <h3>Xrpl accounts</h3>

      <ul>
        <li *ngFor="let xummIdentifier of xrplIdentifiers" class="walletAccount">
          <span>{{xummIdentifier.identityName}}</span> <button class="button button--secondary" [disabled]="!canRemoveSecurityIdentity(xummIdentifier)" (click)="removeSecurityIdentity(xummIdentifier.securityIdentityGuid)">Remove</button>
        </li>
      </ul>

    </div>
  
  </div>

  <div class="provider">

    <h2>Hedera</h2>
    <hr>

    <div class="walletStatus">
      <h3>HashPack Wallet</h3>
      <div>
        <span>Status: </span>
        <span>{{hashPackConnectionStatus}}</span>
      </div>
      <div>
        <span>Account: </span>
        <span>{{hederaConnectedAccount}}</span>
      </div>
      <div>
        <button *ngIf="hederaConnectedAccount != ''" class="button button--secondary" [disabled]="!hederaConnectedAccountConnected" (click)="disconnectHashConnect()">Un-pair wallet</button>
        <button *ngIf="hederaConnectedAccount == ''" class="button button--secondary" [disabled]="!hasHederaPairingKey" (click)="openHashpackModal()">Connect to HashPack</button>
      </div>
    </div>

    <div *ngIf="hashConnectWalletIsNew || (hederaIdentifiers && hederaIdentifiers.length > 0)">
      <h3>Hedera accounts</h3>

      <div *ngIf="hashConnectWalletIsNew && hederaConnectedAccountConnected">
         <button class="button button--secondary"  (click)="addHederaWalletWithHashConnect()">Add {{hederaConnectedAccount}} to my account</button> 
      </div>

      <ul *ngIf="hederaIdentifiers && hederaIdentifiers.length > 0">
        <li *ngFor="let hederaIdentifier of hederaIdentifiers" class="walletAccount">
          <span>{{hederaIdentifier.identityName}}</span> <button class="button button--secondary" [disabled]="!canRemoveSecurityIdentity(hederaIdentifier)" (click)="removeSecurityIdentity(hederaIdentifier.securityIdentityGuid)">Remove</button>
        </li>
      </ul>

    </div>

  </div>

  
  <div class="provider">
    <h2>BitCoin</h2>
    <hr>
    <div class="walletStatus bitcoin">
      <h3>Btc Wallet</h3>

      <div class="provider">
        <span>UniSats Wallet</span>
        <span>Status: </span>
        <span>{{uniSatsConnectionStatus}}</span>
        <div>
          <span>Wallet: {{uniSatsWalletAddress | MiniEvmWalletAddress}}</span>
        </div>
        <div>
          <button [disabled]="!uniSatsDetected" class="button button--primary" (click)="connectUniSatsWallet()">Pair UniSats wallet</button>
        </div>
      </div>
      
      <!--
      <div class="provider">
        <span>Xverse Wallet</span>
        <span>Status: </span>
        <span>{{satsConnectConnectionStatus}}</span>
        <div>
          <span>Wallet: {{satsConnectWalletAddress | MiniEvmWalletAddress}}</span>
        </div>
        <div>
          <button [disabled]="!satsConnectDetected" class="button button--primary" (click)="connectSatsConnectWallet()">Pair Xverse wallet</button>
        </div>
      </div>
      -->
      
    </div>

    <div *ngIf="btcWalletIsNew || (btcIdentifiers && btcIdentifiers.length > 0)">

      <h3>Btc accounts</h3>
      
      <div *ngIf="btcWalletIsNew && connectedBtcAccount">
        <button class="button button--secondary"  (click)="addBtcWallet()">Add {{connectedBtcAccount  | MiniEvmWalletAddress}} to my account</button> 
      </div>

      <ul>
        <li *ngFor="let btcIdentifier of btcIdentifiers" class="walletAccount">
          <span>{{btcIdentifier.identityName  | MiniEvmWalletAddress}}</span> <button class="button button--secondary" [disabled]="!canRemoveSecurityIdentity(btcIdentifier)" (click)="removeSecurityIdentity(btcIdentifier.securityIdentityGuid)">Remove</button>
        </li>
      </ul>

    </div>
  
  </div>
  
  
  <div class="provider">
    <h2>XDC</h2>
    <hr>
    <div class="walletStatus">
      <h3>XDC Wallet</h3>

      <div>
        <span>Status: </span>
        <span>{{ethereumConnectionStatus}}</span>
      </div>
      <div>
        <span>Wallet: {{connectedXdcAccount | MiniEvmWalletAddress}}</span>
      </div>

      <div>
        <button class="button button--primary" (click)="connectEthereumWallet()">Pair XDC wallet</button>
      </div>
    </div>

    <div *ngIf="xdcWalletIsNew || (xdcIdentifiers && xdcIdentifiers.length > 0)">

      <h3>XDC accounts</h3>
      
      <div *ngIf="xdcWalletIsNew && connectedXdcAccount">
        <button class="button button--secondary"  (click)="addEthereumWallet('xdc')">Add {{connectedXdcAccount  | MiniEvmWalletAddress}} to my account</button> 
      </div>

      <ul>
        <li *ngFor="let xdcIdentifier of xdcIdentifiers" class="walletAccount">
          <span>{{xdcIdentifier.identityName  | MiniEvmWalletAddress}}</span> <button class="button button--secondary" [disabled]="!canRemoveSecurityIdentity(xdcIdentifier)" (click)="removeSecurityIdentity(xdcIdentifier.securityIdentityGuid)">Remove</button>
        </li>
      </ul>

    </div>
  
  </div>

  <div class="provider">
    <h2>Ethereum</h2>
    <hr>
    <div class="walletStatus">
      <h3>Ethereum Wallet</h3>

      <div>
        <span>Status: </span>
        <span>{{ethereumConnectionStatus}}</span>
      </div>
      <div>
        <span>Wallet: {{connectedEthereumAccount | MiniEvmWalletAddress}}</span>
      </div>

      <div>
        <button class="button button--primary" (click)="connectEthereumWallet()">Pair Ethereum wallet</button>
      </div>
    </div>

    <div  *ngIf="ethereumWalletIsNew || (ethereumIdentifiers && ethereumIdentifiers.length > 0)">

      <h3>Ethereum accounts</h3>

      <div *ngIf="ethereumWalletIsNew && connectedEthereumAccount">
        <button class="button button--secondary"  (click)="addEthereumWallet('Ethereum')">Add {{connectedEthereumAccount  | MiniEvmWalletAddress}} to my account</button> 
      </div>

      <ul>
        <li *ngFor="let ethereumIdentifier of ethereumIdentifiers" class="walletAccount">
          <span>{{ethereumIdentifier.identityName  | MiniEvmWalletAddress}}</span> <button class="button button--secondary" [disabled]="!canRemoveSecurityIdentity(ethereumIdentifier)" (click)="removeSecurityIdentity(ethereumIdentifier.securityIdentityGuid)">Remove</button>
        </li>
      </ul>

    </div>
  
  </div>
 
  <div class="provider">
    <h2>Flare</h2>
    <hr>
    <div class="walletStatus">
      <h3>Flare Wallet</h3>

      <div>
        <span>Status: </span>
        <span>{{ethereumConnectionStatus}}</span>
      </div>
      <div>
        <span>Wallet: {{connectedFlareAccount | MiniEvmWalletAddress}}</span>
      </div>

      <div>
        <button class="button button--primary" (click)="connectEthereumWallet()">Pair Flare wallet</button>
      </div>
    </div>

    <div *ngIf="flareWalletIsNew || (flareIdentifiers && flareIdentifiers.length > 0)">

      <h3>Flare accounts</h3>

      <div *ngIf="flareWalletIsNew && connectedFlareAccount">
        <button class="button button--secondary"  (click)="addEthereumWallet('Flare')">Add {{connectedFlareAccount  | MiniEvmWalletAddress}} to my account</button> 
      </div>

      <ul>
        <li *ngFor="let flareIdentifier of flareIdentifiers" class="walletAccount">
          <span>{{flareIdentifier.identityName  | MiniEvmWalletAddress}}</span> <button class="button button--secondary" [disabled]="!canRemoveSecurityIdentity(flareIdentifier)" (click)="removeSecurityIdentity(flareIdentifier.securityIdentityGuid)">Remove</button>
        </li>
      </ul>

    </div>
  
  </div>

  <div class="provider">
    <h2>Songbird</h2>
    <hr>
    <div class="walletStatus">
      <h3>Songbird Wallet</h3>

      <div>
        <span>Status: </span>
        <span>{{ethereumConnectionStatus}}</span>
      </div>
      <div>
        <span>Wallet: {{connectedSongbirdAccount | MiniEvmWalletAddress}}</span>
      </div>

      <div>
        <button class="button button--primary" (click)="connectEthereumWallet()">Pair Songbird wallet</button>
      </div>
    </div>

    <div  *ngIf="songbirdWalletIsNew || (songbirdIdentifiers && songbirdIdentifiers.length > 0)">

      <h3>Songbird accounts</h3>

      <div *ngIf="songbirdWalletIsNew && connectedSongbirdAccount">
        <button class="button button--secondary"  (click)="addEthereumWallet('Songbird')">Add {{connectedSongbirdAccount  | MiniEvmWalletAddress}} to my account</button> 
      </div>

      <ul>
        <li *ngFor="let songbirdIdentifier of songbirdIdentifiers" class="walletAccount">
          <span>{{songbirdIdentifier.identityName  | MiniEvmWalletAddress}}</span> <button class="button button--secondary" [disabled]="!canRemoveSecurityIdentity(songbirdIdentifier)" (click)="removeSecurityIdentity(songbirdIdentifier.securityIdentityGuid)">Remove</button>
        </li>
      </ul>

    </div>
  
  </div>

</div>

<div #modal class="modal fade" id="registerModal" tabindex="-1" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-body modal-body--no-margin">
        <div>
          <i class="fa-solid fa-xmark" (click)="closeModal()"></i>
          <app-register-link #registerLink (submitEvent)="submit($event)"></app-register-link>

          <app-waiting [isWaiting]="!!waitingMessage" [message]="waitingMessage"></app-waiting>
        </div>
      </div>
    </div>
  </div>
</div>


<div #xummModal class="modal fade" id="qrCodeModal" tabindex="-1" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-body modal-body--no-margin">
        <i *ngIf="qrCodeImgData" class="fa-solid fa-xmark" (click)="closeModal()"></i>
        <div class="signin-qr" *ngIf="qrCodeImgData">
          <span>
            Link your wallet to your xSpectar.com account
          </span>

          <app-scan-qr-code-v2
            [headerMessage]="qrCodeModalHeaderMessage"
            [showHeaderMessage] = "showQrCodeModalHeaderMessage"
            [showFooterMessage] = "showQrCodeModalFooterMessage"
            [footerMessage]="qrCodeModalFooterMessage"

            [walletType]="walletType"
            [hederaPairingKey]="hederaPairingKey"
            [hasHederaPlugin]="hasHederaPlugin"

            [signInQrUrl]="signInQrUrl"
            [qrCodeImgData]="qrCodeImgData"
            (cancel)="cancelSignIn()"
            (openHederaPlugin)="openHederaPlugin()"
          ></app-scan-qr-code-v2>
        </div>

        <div *ngIf="!qrCodeImgData && qrLinkError">
          <p>We could not link your account at this time.</p>
          <p>Please check the account is not already registered or linked</p>

          <button type="button" class="button button--tertairy-alt" (click)="closeModal()">Close</button>
        </div>
        <app-waiting [isWaiting]="!!waitingMessage" [message]="waitingMessage"></app-waiting>
      </div>
    </div>
  </div>
</div>

import { Component, OnInit } from '@angular/core';
import { finalize } from 'rxjs';
import { IAsset, ILandSaleAssetMetadata } from 'src/app/v2/shared/models';
import { IFreeLandPreMintAssetMetadata } from 'src/app/v2/shared/models/assets/asset-types/free-land-pre-mint-metadata';
import { ServiceResultStatus } from 'src/app/v2/shared/services';
import { environment } from 'src/environments/environment';
import { AccountService } from '../../../shared/services/account.service';

@Component({
  selector: 'app-land',
  templateUrl: './land.component.html',
  styleUrls: ['./land.component.scss']
})
export class LandComponent implements OnInit {
  Object = Object;
  private landSaleAssets: IAsset[] = [];

  sortedCollectionLandSaleAssets: any;
  freePreMintLandSaleAssets: IAsset[] = [];
  finalLandPlotAssets: IAsset[] = [];
  freePreMintLandSalesPlots: number = 0;

  isLoading = true;
  totalPlots = 0;

  constructor(private readonly accountService: AccountService) { }

  ngOnInit(): void {
    this.accountService
      .getAccountAssets(true)
      .pipe(
        finalize(() => this.isLoading = false)
      )
      .subscribe(result => {
        if (result.status === ServiceResultStatus.Success && result.data) {
          this.landSaleAssets = result.data.filter(x => {
            return x.assetSeries.assetCollection?.assetCollectionGuid === environment.landSalePlotsAssetCollectionGuid
              && (x.assetSeries.assetSeriesGuid != environment.freeLandSeriesFromNftPreMintGuid
              && x.assetSeries.assetSeriesGuid != environment.finalLandSeriesFromPackagePreMintGuid
              )
          });

          this.landSaleAssets.sort(this.landSaleAssetSort);

          this.sortedCollectionLandSaleAssets = this.landSaleAssets.reduce((r, a) => {
              r[a.assetSeries.assetSeriesName] = r[a.assetSeries.assetSeriesName] || [];
              r[a.assetSeries.assetSeriesName].push(a);
              return r;
          }, Object.create(null));

          this.finalLandPlotAssets = result.data.filter(x => x.assetSeries.assetSeriesGuid === environment.finalLandSeriesFromPackagePreMintGuid);
          this.freePreMintLandSaleAssets = result.data.filter(x => x.assetSeries.assetSeriesGuid === environment.freeLandSeriesFromNftPreMintGuid);

          if (this.freePreMintLandSaleAssets.length > 0){
            this.freePreMintLandSalesPlots = (this.freePreMintLandSaleAssets[0].assetMetadata as IFreeLandPreMintAssetMetadata).Amount;
          }

          this.landSaleAssets.forEach(e => {
            this.totalPlots += (e.assetMetadata as ILandSaleAssetMetadata).landVoucherQuantity;
          })
          this.totalPlots += (this.freePreMintLandSalesPlots + this.finalLandPlotAssets.length);
        }
      });
  }

  replaceLandSalePackageNameForCSS(name: string) {
    return name.replace(/ /g, '-').toLowerCase();
  }

  private landSaleAssetSort(landSaleAssetA: IAsset, landSaleAssetB: IAsset) {
    const xMetaData = landSaleAssetA.assetMetadata as ILandSaleAssetMetadata;
    const yMetaData = landSaleAssetB.assetMetadata as ILandSaleAssetMetadata;

    if (!xMetaData.landVoucherQuantity) return -1;
    if (!yMetaData.landSaleLevel) return -1;
    return yMetaData.landVoucherQuantity - xMetaData.landVoucherQuantity;
  }

}

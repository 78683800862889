import { Pipe, PipeTransform } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ICurrencyAmount } from '../models/marketplace';

@Pipe({name: 'currencyAmount'})
export class CurrencyAmountPipe implements PipeTransform {
  transform(currencyAmount: ICurrencyAmount): string {
    switch(currencyAmount.currencyGuid) {
      case environment.xrpCurrencyGuid:
        return `${currencyAmount.value} XRP`;
      case environment.xSpectarCurrencyGuid:
        return `${currencyAmount.value} xSPECTAR`;
      case environment.usdCurrencyGuid:
        return `${currencyAmount.value} USD`;
    }
    return `${currencyAmount.value}`;
  }
}
